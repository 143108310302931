@use "sass:math";

@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "primeicons/primeicons.css";

$prefix: "--"; //Prefix string for custom CSS properties

//Merges a variable name with $prefix
@function custom-property-name($name) {
  @return $prefix + $name;
}

// Defines a custom property
@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}

body {
  // Append pre-defined colors in $colors:
  @each $name, $value in $colors {
    @include define-custom-property($name, $value);
  }
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering & set default font-family
*/
body,
body .p-component,
body .p-component input,
body .p-component input::placeholder,
body .p-inputtext::placeholder,
body .p-placeholder,
body .p-element,
body .p-editor-content .ql-editor > p,
body .p-editor-toolbar .ql-formats * {
  font-family: "Ubuntu", sans-serif !important;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

body .p-placeholder {
  font-size: 0.875rem;
}

.p-element,
.p-component,
.p-component:enabled {
  &:focus {
    box-shadow: none !important;
  }
}

::ng-deep .p-dropdown:not(.p-disabled).p-focus {
  &:focus {
    box-shadow: none !important;
  }
}
/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

svg {
  max-width: min(1.5rem, 100%);
  max-height: min(1.5rem, 100%);
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  7. Avoid text overflows and reset heading font properties
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1 {
  font-size: 39px;
  line-height: 46.8px;
}

h2 {
  font-size: 33px;
  line-height: 39.6px;
}

h3 {
  font-size: 28px;
  line-height: 33.6px;
}

h4 {
  font-size: 23px;
  line-height: 27.6px;
}

h5 {
  font-size: 19px;
  line-height: 22.8px;
}

h6 {
  font-size: 17px;
  line-height: 20px;
}

span,
a,
p,
input,
label,
button,
th,
td,
div,
.p-element,
.p-dropdown-label,
.p-inputtext {
  font-size: 14px;
}

/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

// text color
.text-primary {
  color: $brand-primary;
}

// layouts
.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-column {
  flex-direction: column !important;
}

.grid {
  display: grid !important;
}

.block {
  display: block !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-baseline {
  justify-content: baseline !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.gap-min {
  gap: math.div($base-spacing, 2) !important;
}

.gap-1 {
  gap: $base-spacing !important;
}

.gap-2 {
  gap: $base-spacing * 2 !important;
}

.gap-3 {
  gap: $base-spacing * 3 !important;
}

.gap-4 {
  gap: $base-spacing * 4 !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

// padding & margin
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: $base-padding * 1 !important;
}

.p-2 {
  padding: $base-padding * 2 !important;
}

.p-3 {
  padding: $base-padding * 3 !important;
}

.p-4 {
  padding: $base-padding * 4 !important;
}

.p-5 {
  padding: $base-padding * 5 !important;
}

.p-6 {
  padding: $base-padding * 6 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: $base-padding * 1 !important;
}

.pt-2 {
  padding-top: $base-padding * 2 !important;
}

.pt-3 {
  padding-top: $base-padding * 3 !important;
}

.pt-4 {
  padding-top: $base-padding * 4 !important;
}

.pt-5 {
  padding-top: $base-padding * 5 !important;
}

.pt-6 {
  padding-top: $base-padding * 6 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: $base-padding * 1 !important;
}

.pr-2 {
  padding-right: $base-padding * 2 !important;
}

.pr-3 {
  padding-right: $base-padding * 3 !important;
}

.pr-4 {
  padding-right: $base-padding * 4 !important;
}

.pr-5 {
  padding-right: $base-padding * 5 !important;
}

.pr-6 {
  padding-right: $base-padding * 6 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: $base-padding * 1 !important;
}

.pb-2 {
  padding-bottom: $base-padding * 2 !important;
}

.pb-3 {
  padding-bottom: $base-padding * 3 !important;
}

.pb-4 {
  padding-bottom: $base-padding * 4 !important;
}

.pb-5 {
  padding-bottom: $base-padding * 5 !important;
}

.pb-6 {
  padding-bottom: $base-padding * 6 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: $base-padding * 1 !important;
}

.pl-2 {
  padding-left: $base-padding * 2 !important;
}

.pl-3 {
  padding-left: $base-padding * 3 !important;
}

.pl-4 {
  padding-left: $base-padding * 4 !important;
}

.pl-5 {
  padding-left: $base-padding * 5 !important;
}

.pl-6 {
  padding-left: $base-padding * 6 !important;
}

.pinl-0 {
  padding-inline: 0 !important;
}

.pinl-1 {
  padding-inline: $base-padding * 1 !important;
}

.pinl-2 {
  padding-inline: $base-padding * 2 !important;
}

.pinl-3 {
  padding-inline: $base-padding * 3 !important;
}

.pinl-4 {
  padding-inline: $base-padding * 4 !important;
}

.pinl-5 {
  padding-inline: $base-padding * 5 !important;
}

.pinl-6 {
  padding-inline: $base-padding * 6 !important;
}

.pblk-0 {
  padding-block: 0 !important;
}

.pblk-1 {
  padding-block: $base-padding * 1 !important;
}

.pblk-2 {
  padding-block: $base-padding * 2 !important;
}

.pblk-3 {
  padding-block: $base-padding * 3 !important;
}

.pblk-4 {
  padding-block: $base-padding * 4 !important;
}

.pblk-5 {
  padding-block: $base-padding * 5 !important;
}

.pblk-6 {
  padding-block: $base-padding * 6 !important;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: $base-margin * 1 !important;
}

.m-2 {
  margin: $base-margin * 2 !important;
}

.m-3 {
  margin: $base-margin * 3 !important;
}

.m-4 {
  margin: $base-margin * 4 !important;
}

.m-5 {
  margin: $base-margin * 5 !important;
}

.m-6 {
  margin: $base-margin * 6 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: $base-margin * 1 !important;
}

.mt-2 {
  margin-top: $base-margin * 2 !important;
}

.mt-3 {
  margin-top: $base-margin * 3 !important;
}

.mt-4 {
  margin-top: $base-margin * 4 !important;
}

.mt-5 {
  margin-top: $base-margin * 5 !important;
}

.mt-6 {
  margin-top: $base-margin * 6 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: $base-margin * 1 !important;
}

.mr-2 {
  margin-right: $base-margin * 2 !important;
}

.mr-3 {
  margin-right: $base-margin * 3 !important;
}

.mr-4 {
  margin-right: $base-margin * 4 !important;
}

.mr-5 {
  margin-right: $base-margin * 5 !important;
}

.mr-6 {
  margin-right: $base-margin * 6 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: $base-margin * 1 !important;
}

.mb-2 {
  margin-bottom: $base-margin * 2 !important;
}

.mb-3 {
  margin-bottom: $base-margin * 3 !important;
}

.mb-4 {
  margin-bottom: $base-margin * 4 !important;
}

.mb-5 {
  margin-bottom: $base-margin * 5 !important;
}

.mb-6 {
  margin-bottom: $base-margin * 6 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: $base-margin * 1 !important;
}

.ml-2 {
  margin-left: $base-margin * 2 !important;
}

.ml-3 {
  margin-left: $base-margin * 3 !important;
}

.ml-4 {
  margin-left: $base-margin * 4 !important;
}

.ml-5 {
  margin-left: $base-margin * 5 !important;
}

.ml-6 {
  margin-left: $base-margin * 6 !important;
}

.mblk-0 {
  margin-block: 0;
}

.mblk-1 {
  margin-block: $base-margin * 1;
}

.mblk-2 {
  margin-block: $base-margin * 2;
}

.mblk-3 {
  margin-block: $base-margin * 3;
}

.mblk-4 {
  margin-block: $base-margin * 4;
}

.mblk-5 {
  margin-block: $base-margin * 5;
}

.mblk-6 {
  margin-block: $base-margin * 6;
}

// Inputs
.input-group {
  display: flex;
  flex-direction: column;
  gap: math.div($base-spacing, 2);

  label,
  .input-label {
    text-transform: uppercase;
    color: $neutral-500;

    &.transparent {
      color: transparent;
    }
  }

  .optional {
    font-style: italic;
    color: $neutral-300;
  }

  input {
    min-height: 2.5rem;
    border-radius: 0.375rem;
    outline: none;
    padding-inline: $base-padding * 1.5;
  }

  .readonly {
    min-height: 2.9375rem;
    display: flex;
    align-items: center;
    color: #495057;
    background: #f8f9fa;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    cursor: not-allowed;
  }

  // for fields with shorter height. e.g. single checkbox
  .input-field-wrapper {
    display: flex;
    flex: 1;
  }

  .p-inputwrapper {
    .p-autocomplete {
      width: 100%;
    }
  }
}

// Placeholder color for inputs
::placeholder {
  color: var(--bluegray-300);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--bluegray-300);
}

.display-field {
  .field-label {
    text-transform: uppercase;
    color: $neutral-600;
  }

  .field-value {
    color: $neutral-800;
    font-weight: 600;
  }
}

.ng-submitted {
  .p-element.ng-invalid {
    &.p-dropdown,
    &.p-inputtext,
    &.p-inputnumber-input,
    &.p-editor-content,
    &.p-editor-container .p-editor-toolbar.ql-snow {
      border-color: $error-red !important;
    }

    .p-buttonset .p-button {
      border-color: $error-red !important;
    }
  }
  .ng-invalid.ng-touched,
  .ng-invalid.ng-untouched {
    border-color: $error-red !important;

    .p-dropdown {
      border-color: $error-red !important;
    }
  }

  .p-inputgroup:has(.ng-invalid) > * {
    border-color: $error-red !important;

    .p-element.p-inputtext {
      border-color: $error-red !important;
    }
  }
}

.ng-invalid.ng-touched {
  &.p-dropdown,
  &.p-inputtext,
  &.p-inputnumber-input,
  &.p-editor-content,
  &.p-editor-container .p-editor-toolbar.ql-snow {
    border-color: #ced4da !important;
  }
}

// Tab layout customisations
.p-tabview {
  .p-tabview-nav {
    li .p-tabview-nav-link {
      padding: 0.125rem 1rem 0.5rem !important;
    }
  }

  .p-tabview-panels {
    padding-inline: 0;
  }
}

// Input text styling
.p-inputtext {
  &.p-inputtext-xs {
    padding-block: 0.425rem;
  }
}

// Table styling
p-table {
  table {
    .p-datatable-tbody {
      tr {
        &:hover {
          background-color: rgba(100, 116, 139, 0.05);
          cursor: pointer;
        }
      }

      // Input text
      .p-inputtext {
        width: 100% !important;
      }
    }
  }
  .p-sortable-column-icon {
    height: 12px !important;
  }

  .min-cell-content-height {
    table {
      .p-datatable-tbody {
        tr {
          td {
            vertical-align: top;

            .cell-content {
              display: flex;
              height: 47px;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

// Pagination Styling
.p-paginator {
  padding-inline: 0;

  .p-paginator-pages {
    .p-paginator-page {
      min-width: 2.5rem;
      height: 2.5rem;
    }
  }

  .p-paginator-current,
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: 2.5rem;
    height: 2.5rem;
  }

  .p-paginator-current {
    flex: 1;
    justify-content: flex-start;
  }
}

// Chips Styling
.p-chip {
  &.pending {
    background-color: $pending-orange;
    color: $pending-orange-bg;
  }

  .red {
    background-color: $error-red;
    color: $error-red-bg;
  }

  &.paid {
    background-color: $success-green;
    color: $success-green-bg;
  }

  &.paid.partially {
    background-color: $brand-accent;
    color: $brand-accent-bg;
  }
}

// Button Style
.p-button {
  outline: none;
  cursor: pointer;
  transform: translateY(0);
  transition: all 200ms ease-in-out;

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  &.secondary {
    background-color: white;
    color: $brand-primary;
  }

  &.p-button-sm {
    padding: 0.375rem 0.625rem;

    .p-button-icon {
      font-size: 1rem;
    }
  }

  &.p-button-md {
    padding: 0.65rem 0.825rem;
  }

  &.p-button-lg {
    padding: 0.75rem 1.025rem;
  }

  &.icon-right {
    flex-direction: row-reverse;
  }

  .icon {
    width: $base-spacing * 2.5;
    height: $base-spacing * 2.5;
  }

  .p-button-label {
    font-weight: 400;
  }

  &.btn-success-bg {
    background-color: $success-green-bg;
    color: $success-green;
    border-color: $success-green;

    &:hover {
      background-color: $success-green;
      color: #fff;
      border-color: $success-green;
    }
  }

  &.btn-error-bg {
    background-color: $error-red-bg;
    color: $error-red;
    border-color: $error-red;

    &:hover {
      background-color: $error-red;
      color: #fff;
      border-color: $error-red;
    }
  }

  &.p-button-secondary.p-button-text:enabled:hover {
    background: rgba(100, 116, 139, 0.15);
    border-color: transparent;
    color: #64748b;
  }

  &.extra-small {
    padding: 0 0.5rem !important;

    span {
      font-size: 0.75rem;
    }
  }
}

.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(100, 116, 139, 0.15);
  border-color: transparent;
  color: #64748b;
}

// Toggle switch style
$switch-width: 44px;
$switch-height: 26px;
$switch-knob-padding: 4px;
$switch-knob-dimension: calc($switch-height - (2 * $switch-knob-padding));

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.slider:before {
  position: absolute;
  content: "";
  height: $switch-knob-dimension;
  width: $switch-knob-dimension;
  left: $switch-knob-padding;
  bottom: $switch-knob-padding;
  background-color: white;
  transition: all 150ms ease-in-out;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(
    calc($switch-width - $switch-knob-dimension - (2 * $switch-knob-padding))
  );
  -ms-transform: translateX(
    calc($switch-width - $switch-knob-dimension - (2 * $switch-knob-padding))
  );
  transform: translateX(
    calc($switch-width - $switch-knob-dimension - (2 * $switch-knob-padding))
  );
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}

// Dropdown Styling
.p-dropdown {
  width: 100%;

  &.p-focus {
    box-shadow: none !important;
  }

  // &.dropdown-addon {
  //   width: 100% !important;
  // }

  .p-dropdown-item {
    &.p-disabled {
      background-color: #f2f2f2;
    }
  }
}

.p-inputgroup .p-inputwrapper > .p-component {
  &.dropdown-addon {
    width: 100%;
    border: none;
    background: transparent;

    .p-inputtext {
      padding: 0;
      padding-right: 0.5rem;
    }

    .p-dropdown-trigger {
      width: auto;
    }
  }
}

.p-inputnumber {
  width: 100%;
}

// Accordion Styling
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-accordion {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .p-accordiontab {
    .p-accordion-tab {
      margin: 0 !important;
    }
  }
}

// Sidebar Styles
.p-sidebar-right {
  min-width: 30rem;
  width: 50% !important;

  &.w-25 {
    width: 25% !important;
  }
}

// Datepicker Styles
.p-calendar-w-btn,
.p-calendar {
  width: 100%;

  .p-inputtext {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

    &:has(+ .p-button-icon-only) {
      border-right: 0;
    }

    &:hover {
      & + .p-button-icon-only {
        border-color: #8b5cf6;
      }
    }
  }

  .p-button-icon-only {
    background-color: transparent;
    border-left: 0;
    border-color: rgb(206, 212, 218);

    &:hover {
      background-color: transparent;
      border-color: rgb(206, 212, 218);
    }

    svg {
      path {
        fill: #6c757d;
      }
    }
  }
}

// Context Menu Styles
.p-menuitem {
  &.danger {
    span {
      color: $error-red !important;
    }

    &.p-menuitem-active {
      .p-menuitem-link {
        background-color: $error-red-bg !important;
      }
    }
  }
}

// Confirmation Dialog Modal
.p-confirm-dialog {
  &.delete-confirmation-modal {
    .p-dialog-header {
      .p-dialog-title {
        font-size: 1rem;
      }
    }

    .p-dialog-content {
      color: $error-red;
      .p-confirm-dialog-icon {
        font-size: 1.5rem;
      }
    }
  }
}

.user-overlay {
  border-radius: 1rem;

  .p-overlaypanel-content {
    padding-inline: 0;
    padding-bottom: 0;

    hr {
      height: 1px;
      background-color: #ccc;
      border: none;
    }

    .p-button-link {
      border-radius: 0;

      &.grey-hover:hover {
        background-color: $neutral-50;
      }

      .p-button-label {
        text-align: left;
      }
    }
  }
}

.no-data-image {
  width: 12rem;
}

.link {
  cursor: pointer;
  color: $brand-primary;
  text-decoration: underline;
}

.white-space-pre {
  white-space: pre;
}

.card {
  border: 1px solid $neutral-100;
  border-radius: $base-spacing;
  padding: $base-padding * 2;
}

.banner {
  border-radius: $base-spacing * 2;
  width: 100%;
  padding: $base-padding * 3;
  background-color: rgba($brand-primary, 0.05);

  .title {
    font-weight: 600;
    font-size: 1rem !important;

    &.title-sm {
      font-size: 0.875rem !important;
    }
  }
}
