// spacing
$base-margin: 0.5rem;
$base-padding: 0.5rem;
$base-spacing: 0.5rem;

// color pallette
$neutral-900: #263238;
$neutral-800: #37474f;
$neutral-700: #455a64;
$neutral-600: #546e7a;
$neutral-500: #607d8b;
$neutral-400: #78909c;
$neutral-300: #90a4ae;
$neutral-200: #b0bec5;
$neutral-100: #cfd8dc;
$neutral-50: #eceff1;
$neutral-00: #ffffff;

$brand-primary: #8b5cf6;
$brand-accent: #237eab;
$brand-accent-bg: #f0f9ff;

$success-green: #0aa882;
$success-green-bg: #e5fff9;
$warning-yellow: #eab600;
$warning-yellow-bg: #fff9e5;
$error-red: #e53935;
$error-red-bg: #fdf0f1;
$pending-orange: #d68910;
$pending-orange-bg: hsl(37, 90%, 90%);

$shadow-color: rgba(176, 190, 197, 0.25);

$colors: (
  "brand-primary": $brand-primary,
  "brand-accent": $brand-accent,
  "brand-accent-bg": $brand-accent-bg,
  "success-green": $success-green,
  "success-green-bg": $success-green-bg,
  "warning-yellow": $warning-yellow,
  "warning-yellow-bg": $warning-yellow-bg,
  "error-red": $error-red,
  "error-red-bg": $error-red-bg,
  "pending-orange": $pending-orange,
  "pending-orange-bg": $pending-orange-bg,
  "neutral-900": #263238,
  "neutral-800": #37474f,
  "neutral-700": #455a64,
  "neutral-600": #546e7a,
  "neutral-500": #607d8b,
  "neutral-400": #78909c,
  "neutral-300": #90a4ae,
  "neutral-200": #b0bec5,
  "neutral-100": #cfd8dc,
  "neutral-50": #eceff1,
  "neutral-00": #ffffff,
);

// other variables
$header-height: 3.5rem;
$side-nav-width-opened: 15rem;
$side-nav-width-collapsed: 4.5rem;
